<template>
  <div class="more-icon">
    <template v-if="$store.state.language">
      <div class="text">更<br />多</div>
    </template>
    <template v-else>
      <div class="text-en">more</div>
    </template>
    <div class="more-icon" :class="{'icon-active': isShow }" @mouseenter="moreAnimation" @mouseleave="moreAnimation2">
      <i class="left-icon"></i>
      <i class="right-icon"></i>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    moreAnimation() {
      this.isShow = true
    },
    moreAnimation2() {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.more-icon {
  min-width: 35px;
  height: 20.87px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  text-align: center;
  position: relative;
  .text {
    margin: -5px 0 0 0;
    line-height: 14px;
  }
  .text-en {
    padding: 0 12px;
  }
  .more-icon {
    transition: all 1.2s;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    .left-icon {
      width: 10.75px;
      height: 20.87px;
      background: url("../../static/img/home/rice_left.png") no-repeat left
        center;
      background-size: 100% 100%;
    }
    .right-icon {
      width: 10.75px;
      height: 20.87px;
      background: url("../../static/img/home/rice_left.png") no-repeat left
        center;
      background-size: 100% 100%;
      transform: rotateY(180deg);
    }
  }
  .icon-active {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}
</style>