<template>
  <div class="brand-basics">
    <div class="brand-title-icon">
      <img src="../../static/img/brand/title_icon.png" alt="" class="icon">
      <template v-if="$store.state.language">
        <img src="../../static/img/brand/title_CH.png" alt="" class="text-icon">
      </template>
      <template v-else>
        <img src="../../static/img/brand/title_EN.png" alt="" class="text-icon">
      </template>
      <img src="../../static/img/brand/title_icon.png" alt="" class="icon ic-r">
    </div>
    <div class="search">
      <searchBox :areaCode.sync="areaCode" :findStr.sync="findStr"></searchBox>
    </div>
    <div class="brand-list">
      <div class="brand-item" v-for="(item,index) in list" :key="index" :style="'background:url('+getImgUrl(index)+') no-repeat center;background-size:100% 100%'" @click="goDetails(item)">
        <img :src="item.coverImg" alt="" class="brand-img">
        <div class="brand-text-box">
          <div class="brand-title">
            <c-title :width="11" space="2">
              <div class="brand-name">{{$store.state.language ? item.brandName : item.brandNameE }}</div>
            </c-title>
          </div>
          <div class="brand-text" :class="{'indent-icon': $store.state.language}" v-html="$store.state.language ? item.brandProfile : item.brandProfileE"></div>
          <div class="more">
            <more-icon></more-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import cTitle from '@/components/cTitle/cTitle.vue'

import searchBox from '@/components/searchBox/searchBox'
import { brandPageList } from '@/api/brand'
import moreIcon from '@/components/moreIcon/moreIcon'

export default {
  name: 'brandList',
  components: {
    cTitle,
    searchBox,
    moreIcon
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 18,
      total: 0,
      list: [],
      areaCode: '',
      findStr: ''
    }
  },
  watch: {
    areaCode(val) {
      this.getPageList()
    },
    findStr(val) {
      this.getPageList()
    }
  },
  created() {
    this.getPageList()
  },
  mounted() {},
  methods: {
    getPageList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        findStr: this.findStr,
        areaCode: this.areaCode
      }
      brandPageList(params).then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          let data = res.data
          this.total = data.total
          this.list = data.list
        }
      })
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getPageList()
      // console.log(`当前页: ${val}`)
    },
    // 获取图片
    getImgUrl(index) {
      if (index >= 9) {
        index = index - 9
      }
      return require('@/static/img/brand/item_bg' + (index + 1) + '.png')
    },
    goDetails(item) {
      this.$router.push({
        path: '/brand/brandItem',
        query: {
          brandId: item.brandId
        }
      })
    }
  }
}
</script>
<style lang="scss">
.brand-basics {
  width: 1140px;
  margin: 40px auto 0;
  .brand-title-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 468px;
      height: 28px;
    }
    .ic-r {
      transform: rotateY(180deg);
    }
    .text-icon {
      // width: 186px;
      height: 48px;
    }
  }
  .search {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 0 0;
  }
  .brand-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;
    .brand-item {
      width: 370px;
      height: 260px;
      // background: url("../../static/img/home/brand-bg01.png") no-repeat center;
      // background-size: 100% 100%;
      display: flex;
      position: relative;
      margin: 0 14px 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .brand-img {
        width: 220px;
        height: 100%;
        display: block;
        position: relative;
        z-index: 2;
      }
      .brand-text-box {
        position: relative;
        z-index: 2;
        color: #fff;
        flex: 1;
        .brand-title {
          display: flex;
          align-items: center;
          margin: 21px 20px 0 20px;
          font-size: 16px;
          .brand-name {
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-align: center;
            font-weight: bold;
            flex: 1;
            line-height: 22px;
          }
        }
        .brand-text {
          width: 108px;
          margin: 25px 0 0 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          font-size: 12px;
          line-height: 18px;
          word-break: break-all;
        }
        .indent-icon {
          background: url('../../static/img/home/indent-icon.png') no-repeat
            left top 4px;
          background-size: 11px 10px;
          // text-indent: 20px;
        }
        .time {
          font-size: 12px;
          line-height: 14px;
          margin: 10px 0 0 20px;
        }
        .more {
          position: absolute;
          right: 22px;
          bottom: 22px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    .el-pager {
      font-weight: initial;
      .number {
        font-size: 15px;
        color: #595757;
        padding: initial;
        min-width: 30px;
        height: 30px;
        margin: 0 5px;
      }
      .active {
        color: #ffffff;
        background: #843322;
        border-radius: 50%;
      }
    }
    .btn-prev {
      transform: rotateY(180deg);
    }
    .btn-prev,
    .btn-next {
      height: 30px;
      background: url('../../static/img/brand/page_icon.png') no-repeat center;
      background-size: 18px 30px;
      .el-icon {
        display: none;
      }
    }
  }
}
</style>